<template>
    <div ref="chart" id="calculo"></div>
  </template>
  
  <script>
  import ApexCharts from 'apexcharts';
  import apiMethods from '../../src/views/services/homeService';
  
  
  export default {
    name: 'ChartComponent',
    data() {
      return {
        parametros: [],
      };
    },
    mounted() {
      // Removing the call from here to avoid duplicate rendering
      // this.renderChart();
    },
    async created() {
      try {
        const retorno = await apiMethods.graficoParametros();
        
        if (retorno && Array.isArray(retorno)) {
          this.parametros = retorno;
          this.renderChart(retorno);
        } else {
          console.error('Invalid data format:', retorno);
        }
      } catch (error) {
        console.error('Error fetching parametros:', error);
      }
    },
    methods: {
      renderChart(parametros) {
        document.querySelector("#calculo").innerHTML = '';
        if (!parametros || !Array.isArray(parametros)) {
          console.error('Invalid parametros:', parametros);
          return;
        }
  
        const dataPorMes = {};
  
        parametros.forEach((item) => {
          if (item && item.dt_base) {
            const date = new Date(item.dt_base);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
            dataPorMes[formattedDate] = item;
          } else {
            console.error('Invalid item format:', item);
          }
        });
  
        const ultimos12Meses = Object.keys(dataPorMes).slice(-5);
  
        const labels = [];
        const custoEnergiaMes = [];
        const economiaDiesel = [];
        const economiaFinal = [];
  
        ultimos12Meses.forEach((key) => {
          const item = dataPorMes[key];
          if (item) {
            labels.push(key);
            custoEnergiaMes.push(item.custo_energia_mes || 0);
            economiaDiesel.push(item.economia_diesel || 0);
            economiaFinal.push(item.economia_final || 0);
          } else {
            console.error('Invalid data for key:', key);
          }
        });
  
        const series = [
          { name: 'Custo energia', data: custoEnergiaMes },
          { name: 'Economia diesel', data: economiaDiesel },
          { name: 'Economia final', data: economiaFinal },
        ];
  
        const options = {
          chart: {
            height: 200,
            type: 'bar',
            toolbar: { show: false },
          },
          plotOptions: {
            bar: { horizontal: false, columnWidth: '40px', borderRadius: 7 },
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 2,
            colors: ['#ee663a', '#ffdc96', '#4274a0'],
          },
          series: series,
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            markers: { width: 12, height: 12, radius: 6 },
            labels: { colors: ['#ee663a', '#ffdc96', '#4274a0'] },
          },
          xaxis: {
            categories: ultimos12Meses,
            labels: {
                style: {
                colors: 'white' // Defina a cor dos rótulos como preto
                }
            }
          },
          yaxis: {
            show: true,
            labels: {
                 style: {
                    colors: 'white' // Defina a cor dos rótulos como preto
                 },
              formatter: function (val) {
                return "R$ " + val.toLocaleString('pt-BR', {
                style: 'decimal'
              });
              },
            },
          },
          fill: { opacity: 1 },
          tooltip: {
            y: {
              formatter: function (val) {
                return 'R$ ' + Number(val);
              },
            },
           
            
          },
          grid: {
            borderColor: 'white',
            strokeDashArray: 3,
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: false } },
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
          },
          colors: ['#ee663a', '#ffdc96', '#4274a0'],
        };
  
        const chart = new ApexCharts(this.$refs.chart, options);
        chart.render();
      },
    },
  };
  </script>
  