<template>
  <div class="app-header d-flex align-items-center">
    <div class="app-brand-sm d-md-none d-sm-block">
      <router-link to="/">
        <img src="assets/images/apolo_logo-semFundo.png" class="logo" alt="Apolo Lab" />
      </router-link>
    </div>
    <!-- App brand sm end -->
    <!-- App header actions start -->
    <div class="header-actions">
      <div class="dropdown ms-2">
        <a class="dropdown-toggle d-flex align-items-center user-settings" href="#!" role="button"
           data-bs-toggle="dropdown" aria-expanded="false">
          <div class="user-initials" v-if="initials">{{ initials }}</div>
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-sm shadow-sm gap-3">
          <router-link class="dropdown-item d-flex align-items-center py-2" to="/"><i
              class="fs-3 icon-home me-3"></i>Home</router-link>
          <a class="dropdown-item d-flex align-items-center py-2" @click="openModal()"><i
              class="fs-3 icon-calculate me-3"></i>Simulador</a>
          <router-link class="dropdown-item d-flex align-items-center py-2" to="/gestaoUsuarios"><i
              class="fs-3 icon-users me-3"></i>Usuários</router-link>
          <a class="dropdown-item d-flex align-items-center py-2" @click="logout()"><i class="icon-log-out fs-4 me-3"></i>Sair</a>
        </div>
      </div>
    </div>
    <!-- App header actions end -->
  </div>
  <modal ref="modal" :title="'Simulador de economia'"></modal>
</template>



<script>
import modal from './Simulador';

export default {
  name: "SidBar",
  components: {
    modal
  },
  data() {
    return {
      user: '',
      initials: ''
    }
  },
  created() {
    this.user = sessionStorage.getItem('user');
    this.initials = this.generateInitials(this.user);
  },
  methods: {
    openModal() {
      if (this.$refs.modal && typeof this.$refs.modal.show === 'function') {
        this.$refs.modal.show();
      } else {
        console.error('Modal component is not properly referenced ou method show does not exist.');
      }
    },
    generateInitials(name) {
      if (!name) return '';
      const names = name.split(' ');
      const initials = names.map(n => n.charAt(0).toUpperCase()).slice(0, 2).join('');
      return initials;
    },
    logout() {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.user-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #FA8E10;
  color: #ffffff;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}

</style>