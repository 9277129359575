<template>
  <div v-if="isVisible" class="modal" @animationend="handleAnimationEnd">
    <div class="modal-content" :class="{ 'slide-out': isClosing }">
      <div class="modal-header">
        <h5 class="modal-title h4">Simulador de economia</h5>
        <button type="button" class="btn-close" @click="hide" aria-label="Close">&times;</button>
      </div>
      <div class="modal-body">
        <slot>
          <div class="row">
            <div class="col-lg-3 col-sm-4 col-6">
              <div class="card mb-3">
                <div class="card-body">
                  <label class="form-label">Custo de energia elétrica R$/MWh</label>
                  <input type="number" v-model="simularCustoEletrica" name="custoEletrica" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-4 col-6">
              <div class="card mb-3">
                <div class="card-body">
                  <label class="form-label">Custo de diesel R$/litro</label>
                  <input type="number" v-model="simularCustoDiesel" name="custoDiesel" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-4 col-6">
              <div class="card mb-3">
                <div class="card-body">
                  <label class="form-label">Adição de energia elétrica (caldeira) kW</label>
                  <input type="number" v-model="simularEletricaCaldeira" name="eletricaCaldeira" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-4 col-6">
              <div class="card mb-3">
                <div class="card-body">
                  <label class="form-label">Adição de energia solar (coletores) kW</label>
                  <input type="number" v-model="simularEnergiaSolar" name="energiaSolar" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-xl-4">
              <div class="card mb-4">
                <div class="card-body">
                  <label class="form-label">Economia de consumo de diesel litro/mês</label>
                  <input type="text" v-model="simularEconomiaCustoDieselMes" name="economiaCustoDieselMes"
                    class="form-control" readonly />
                  <label class="form-label">Consumo de energia elétrica MWh/mês</label>
                  <input type="text" v-model="simularConsumoEnergiaEletrMes" name="consumoEnergiaEletrMes"
                    class="form-control" readonly />
                  <label class="form-label">Economia de diesel R$/mês</label>
                  <input type="text" v-model="simularEconomiaDieselMes" name="economiaDieselMes" class="form-control"
                    readonly />
                  <label class="form-label">Custo de energia R$/mês</label>
                  <input type="text" v-model="simularCustoEnergiaMes" name="custoEnergiaMes" class="form-control"
                    readonly />
                  <label class="form-label">Economia final R$/mês</label>
                  <input type="text" v-model="simularEconomiaFinalMes" name="economiaFinalMes" class="form-control"
                    readonly />
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-8">
              <div class="card mb-4">
                <div class="card-body">
                  <div id="calculoSimulador"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="simulador(true)" class="btn btn-warning">Simular</button>
            <button type="button" class="btn btn-warning" @click="hide">Fechar</button>
            <button type="submit" name="input" @click="salvar()" class="btn btn-warning">Salvar</button>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import ApexCharts from 'apexcharts';
import apiMethods from '../../src/views/services/homeService';
export default {
  name: 'ModalPerfil',
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      isVisible: false,
      isClosing: false,
      simularCustoEletrica: 0,
      simularCustoDiesel: 0,
      simularEletricaCaldeira: 0,
      simularEnergiaSolar: 0,
      simularEconomiaCustoDieselMes: 0,
      simularConsumoEnergiaEletrMes: 0,
      simularEconomiaDieselMes: 0,
      simularEconomiaFinalMes: 0,
      simularCustoEnergiaMes: 0,
      simular: false,
      /// input ////

      simularEconomiaCustoDieselMesInput: null,
      simularConsumoEnergiaEletrMesInput: null,
      simularEconomiaDieselMesInput: null,
      simularCustoEnergiaMesInput: null,
      simularEconomiaFinalMesInput: null,

    };
  },

  watch: {
    simularCustoEletrica(newValue) {
      this.simulador();
    },
    simularCustoDiesel(newValue) {
      this.simulador();
    },
    simularEletricaCaldeira(newValue) {
      this.simulador();
    },
    simularEnergiaSolar(newValue) {
      this.simulador();
    }
  },
  methods: {
    show() {
      this.isClosing = false;
      this.isVisible = true;
    },
    hide() {
      this.isClosing = true;
    },
    handleAnimationEnd() {
      if (this.isClosing) {
        this.isVisible = false;
        this.isClosing = false; // Reset isClosing
      }
    },
    simulador(simular) {
      let series = [];
      let eletricidadeInput = parseFloat(this.simularCustoEletrica);
      let eletricCaldeiraInput = parseFloat(this.simularEletricaCaldeira);
      let energiaSolarInput = parseFloat(this.simularEnergiaSolar);
      let pciDieselInput = parseFloat(11.94); // parseFloat(document.getElementById('pciDiesel').value);
      let densidadeDieselInput = parseFloat(0.840); // parseFloat(document.getElementById('densidadeDiesel').value);
      let eficienciaCaldeiraInput = parseFloat(0.7); // parseFloat(document.getElementById('eficienciaCaldeira').value);
      let custoDieselInput = parseFloat(this.simularCustoDiesel);
      let economiaCustoDieselMesInput = parseFloat(this.simularEconomiaCustoDieselMes);
      let consumoEnergiaEletrMesInput = parseFloat(this.simularConsumoEnergiaEletrMes);
      let economiaDieselInput = this.simularEconomiaDieselMes;
      let custoEnergiaInput = this.simularCustoEnergiaMes;
      let economiaFinalInput = this.simularEconomiaFinalMes;

      var eletricCaldeiraValue = eletricCaldeiraInput;
      var consumoEnergiaEletricaMesValue = (eletricCaldeiraValue * 24 * 30) / 1000;
      consumoEnergiaEletrMesInput = consumoEnergiaEletricaMesValue;

      let consumoEnergiaEletricaMesValueFormatado = consumoEnergiaEletricaMesValue.toLocaleString(
        'pt-BR', {
        style: 'decimal'
      });
      this.simularConsumoEnergiaEletrMes = consumoEnergiaEletricaMesValueFormatado

      var eletricCaldeiraValue = eletricCaldeiraInput;
      var energiaSolarValue = energiaSolarInput;
      var pciDieselValue = pciDieselInput;
      var densidadeDieselValue = densidadeDieselInput;
      var eficienciaCaldeiraValue = eficienciaCaldeiraInput

      let soma = eletricCaldeiraValue + energiaSolarValue;
      if (!isNaN(eletricCaldeiraValue) && !isNaN(energiaSolarValue) && !isNaN(pciDieselValue) && !
        isNaN(
          densidadeDieselValue) && !isNaN(eficienciaCaldeiraValue)) {
        let custoDieselMesValue = (soma / pciDieselValue / densidadeDieselValue /
          eficienciaCaldeiraValue) * 24 * 30;

        economiaCustoDieselMesInput = custoDieselMesValue;

        let custoDieselMesValueFormatado = custoDieselMesValue.toLocaleString('pt-BR', {
          style: 'decimal'
        });
        let valorMaximo = Number("120000.000")
        if (economiaCustoDieselMesInput >= valorMaximo && simular === true) {
          Swal.fire({
            icon: "error",
            title: "Verifique os valores adicionados",
            text: "Economia de consumo de diesel acima de 120.000,00 litros/mês",
          });

        }
        this.simularEconomiaCustoDieselMes = custoDieselMesValueFormatado;
      }

      let eletricidade = eletricidadeInput;
      let custoDieselValue = custoDieselInput;
      let economiaDieselValue = economiaDieselInput;
      let custoEnergiaValue = custoEnergiaInput;
      let economiaFinalValue = economiaFinalInput;
      let economiaDieselMes = economiaCustoDieselMesInput * custoDieselValue;
      let custoEnergiaMes = consumoEnergiaEletrMesInput * eletricidade
      let calculado = economiaDieselMes - custoEnergiaMes;
      let economiaDieselMesFormatado = economiaDieselMes.toLocaleString('pt-BR', {
        style: 'decimal'
      });
      let custoEnergiaMesFormatado = custoEnergiaMes.toLocaleString('pt-BR', {
        style: 'decimal'
      });

      let calculadoFormatado = calculado.toLocaleString('pt-BR', {
        style: 'decimal'
      });

      this.simularEconomiaDieselMes = economiaDieselMesFormatado;
      this.simularCustoEnergiaMes = custoEnergiaMesFormatado;
      this.simularEconomiaFinalMes = calculadoFormatado;

      this.simularEconomiaCustoDieselMesInput = (economiaCustoDieselMesInput);
      this.simularConsumoEnergiaEletrMesInput = (consumoEnergiaEletrMesInput);
      this.simularEconomiaDieselMesInput = (economiaDieselMes);
      this.simularCustoEnergiaMesInput = (custoEnergiaMes);
      this.simularEconomiaFinalMesInput = (calculado);

      
      series.push({
        name: "Custo energia",
        data: [custoEnergiaMes],
      });

      series.push({
        name: "Economia diesel",
        data: [economiaDieselMes],
      });

      series.push({
        name: "Economia final",
        data: [calculado],
      });

      if (simular === true && calculado != null && custoEnergiaMes != null && economiaDieselMes != null
        && consumoEnergiaEletrMesInput != null && economiaCustoDieselMesInput != null
      ) {
        this.grafico(series);
      }
    },

    grafico(series) {

      document.querySelector("#calculoSimulador").innerHTML = '';
      let dataAtual = new Date();

      // Obtém o ano, mês e dia atual
      let ano = dataAtual.getFullYear();
      let mes = dataAtual.getMonth() + 1; // Os meses são indexados a partir de 0, então adicionamos 1
      let dia = dataAtual.getDate();
      let dataFormatada = `${ano}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;

      if (series === null) {
        series = [{
          name: "Custo energia",
          data: [0],
        },
        {
          name: "Economia diesel",
          data: [0],
        },
        {
          name: "Economia final",
          data: [0],
        },
        ]

      }
      var options = {

        chart: {
          height: 450,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "40px",
            borderRadius: 7,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#ee663a', '#ffdc96', '#4274a0'],
        },
        series: series,
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
          labels: {
            colors: ['#ee663a', '#ffdc96', '#4274a0']
          }
        },
        xaxis: {
          categories: [dataFormatada],
          labels: {
            style: {
              colors: 'white' // Defina a cor dos rótulos como preto
            }
          }
        },
        yaxis: {
          show: true, // Exibir eixo Y
          labels: {

            formatter: function (val) {
              return val // Formato dos rótulos do eixo Y
            }
          }
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function (val) {
              return "R$ " + val.toLocaleString('pt-BR', {
                style: 'decimal'
              });
            },
          },
        },
        grid: {
          borderColor: 'white',
          strokeDashArray: 3,
          xaxis: {
            labels: {
              style: {
                colors: 'white' // Defina a cor dos rótulos como preto
              }
            },
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        colors: ['#ee663a', '#ffdc96', '#004971'],

      };
      var chart = new ApexCharts(document.querySelector("#calculoSimulador"), options);
      chart.render();
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    async salvar() {

      if (this.simularEconomiaCustoDieselMesInput != null &&
        this.simularConsumoEnergiaEletrMesInput != null &&
        this.simularEconomiaDieselMesInput != null &&
        this.simularCustoEnergiaMesInput != null &&
        this.simularEconomiaFinalMesInput != null &&
        this.simularCustoEletrica != null &&
        this.simularCustoDiesel != null
      ) {

        let simuladorData = [];

        simuladorData.push({
          simularEconomiaCustoDieselMes: this.formatNumber(this.simularEconomiaCustoDieselMesInput),
          simularConsumoEnergiaEletrMes: this.formatNumber(this.simularConsumoEnergiaEletrMesInput),
          simularEconomiaDieselMes: this.formatNumber(this.simularEconomiaDieselMesInput),
          simularCustoEnergiaMes: this.formatNumber(this.simularCustoEnergiaMesInput),
          simularEconomiaFinalMes: this.formatNumber(this.simularEconomiaFinalMesInput),
          simularCustoEletrica: this.formatNumber(this.simularCustoEletrica),
          simularCustoDiesel: this.formatNumber(this.simularCustoDiesel),
        });

        let salvar = await apiMethods.salvarSimulador(simuladorData);

        if (salvar) {

          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Dados Salvos Com Sucesso !!",
          }).then(() => {
            location.reload(); // Atualiza a página após clicar "OK"
          });

        } else {
          Swal.fire({
            icon: "error",
            title: "Ops! Erro ao Salvar",
            text: "Verifique se Todos os Campos Estão preenchidos !!",
          });
          return;
        }

      } else {
        Swal.fire({
          icon: "error",
          title: "Ops! Erro ao Salvar",
          text: "Verifique se Todos os Campos Estão preenchidos !!",
        });
        return;
      }
    }

  }

};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgb(65, 64, 64);
  box-sizing: border-box;
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
}

.btn-close:hover {
  color: #ff0000;
}

.modal-header,
.modal-body {
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.modal-footer button {
  margin-left: 10px;
  /* Adicionando margem à esquerda */
}
</style>