<template>
    <div class="row">
        <div class="col-xl-4 col-sm-12 col-12">
            <div class="card mb-4">
                <div class="card-header">
                    <h5 class="card-title">Geração de energia </h5>
                    <p>Atualização: {{ data }} </p>
                </div>
                <div class="card-body">
                    <!-- Row start -->
                    <div class=" g-4">
                        <div class="col-sm-12 col-12">
                            <div class="border rounded-2 d-flex align-items-center flex-row p-2">
                                <div class="me-2">
                                    <div id="sparkline1"></div>
                                </div>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <div class="fs-4 fw-bold">
                                            {{ geracao }} MW</div>
                                        <div class="ms-2 text-primary fw-bold d-flex">
                                            <i v-if="isMaiorGeracao === true" class="icon-trending-up fs-4 me-1"></i>
                                            <i v-else="isMaiorGeracao === false"
                                                class="icon-trending-down fs-4 me-1"></i>
                                            &nbsp;{{ porcentagemGeracao.toFixed(2) }} %
                                        </div>
                                    </div>
                                    <small class="text-dark">Geração</small>
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="col-sm-12 col-12">
                            <div class="border rounded-2 d-flex align-items-center flex-row p-2">
                                <div class="me-2">
                                    <div id="sparkline2"></div>
                                </div>
                                <div class="m-0">
                                    <div class="d-flex align-items-center">
                                        <div class="fs-4 fw-bold">
                                            {{ carga }} MW</div>
                                        <div class="ms-2 text-danger fw-bold d-flex">
                                            <i v-if="isMaiorCarga === true" class="icon-trending-up fs-4 me-1"></i>
                                            <i v-else="isMaiorCarga === false" class="icon-trending-down fs-4 me-1"></i>
                                            &nbsp;{{ porcentagemCarga.toFixed(2) }} %
                                        </div>
                                    </div>
                                    <small class="text-dark">Carga</small>
                                </div>
                                <br>
                            </div>
                        </div>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 ">
            <div class="card mb-4">
                <div class="card-header">
                    <h5 class="card-title">Valores acumulados no dia</h5>
                    <div id="balancoEnergeticoAtualizacao"></div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6 class="text-center">Geração por região (MW)</h6>
                            <div id="pie" class="auto-align-graph"></div>
                        </div>
                        <div class="col-md-6">
                            <h6 class="text-center">Geração por modal (MW)</h6>
                            <div id="pie2" class="auto-align-graph"></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 ">
            <div class="card mb-4">
                <div class="card-body">
                    <h5>Curva de geração (MW) </h5>
                    <div id="dataMaisRecenteGraficoLinha"></div>
                    <div id="graficoEnergetico"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApexCharts from 'apexcharts';
import apiMethods from '../../src/views/services/homeService';
import moment from 'moment';
import 'moment-timezone';
import { ref, onMounted } from 'vue';
export default {
    name: 'GeracaoHoje',
    setup() {
        const currentDate = ref('');

        onMounted(() => {
            // Defina o fuso horário padrão para o Brasil
            moment.tz.setDefault('America/Sao_Paulo');
            // Use o Moment.js para obter a data e hora atual no fuso horário do Brasil
            currentDate.value = moment.tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');
        })
    },
    data() {
        return {
            carga: 0,
            geracao: 0,
            data: '',
            isMaiorGeracao: false,
            isMaiorCarga: false,
            porcentagemGeracao: 0,
            porcentagemCarga: 0,
            parametros: [],
            parametrosTodos: [],
            porcentagemGeracao: 0,
            porcentagemCarga: 0,
            parametros: [],
            parametrosTodos: [],
        };
    },
    async created() {
        try {
            const retorno = await apiMethods.graficoBalancoEnergeticoAgora();
            // console.log('Data fetched:', retorno);
            if (retorno && Array.isArray(retorno)) {
                this.parametros = retorno;
                this.renderChart(retorno);
            } else {
                // console.error('Invalid data format:', retorno);
            }
        } catch (error) {
            console.error('Error fetching parametros:', error);
        }
        try {
            const retorno2 = await apiMethods.graficoBalancoenErgeticoPie();
            this.renderChart2(retorno2);

        } catch (error) {
            console.error('Invalid data error:', error);
        }
        try {
            const retorno3 = await apiMethods.graficoBalancoEnergeticoMinuto();
               console.log('Data fetched:', retorno3);
            if (retorno3 && Array.isArray(retorno3)) {
                this.renderChart3(retorno3);
            } else {
                console.error('Invalid data format:', retorno3);
            }
        } catch (error) {
            console.error('Invalid data error:', error);
        }
        try {
            const agora = await apiMethods.balancoenergeticoAgora();

            this.balancoGeracaoCarga(agora);


        } catch (error) {
            console.error('Invalid data error:', error);
        }
    },
    watch: {
        geracao(newVal, oldVal) {
            console.log(`Geracao changed from ${oldVal} to ${newVal}`);
            // Adicione o código que você deseja executar quando 'geracao' mudar
        },
        carga(newVal, oldVal) {
            console.log(`Carga changed from ${oldVal} to ${newVal}`);
            // Adicione o código que você deseja executar quando 'carga' mudar
        }
    },

    methods: {
        renderChart(parametros) {
            document.querySelector("#pie").innerHTML = '';

            moment.locale('pt-br')
            let balancoEnergetico = parametros[0]
            var series = [];
            var labels = [];
            let balancoEnergeticodata = moment(balancoEnergetico.data).utc().format('DD-MM-YYYY HH:mm');
            let balancoEnergeticodataHtml = document.getElementById("balancoEnergeticoAtualizacao");

            // Atualizar o conteúdo da div com a data
            balancoEnergeticodataHtml.innerText = "Atualização: " + balancoEnergeticodata;

            var sudesteECentroOeste_total = parseFloat(balancoEnergetico.sudesteECentroOeste_total).toFixed(2);
            var sul_total = parseFloat(balancoEnergetico.sul_total).toFixed(2);
            var nordeste_total = parseFloat(balancoEnergetico.nordeste_total).toFixed(2);
            var norte_total = parseFloat(balancoEnergetico.norte_total).toFixed(2);

            series.push(Number(sudesteECentroOeste_total));
            series.push(Number(sul_total));
            series.push(Number(nordeste_total));
            series.push(Number(norte_total));
            var options = {
                chart: {
                    width: 300,
                    type: "pie",
                },
                labels: ["Sudeste / Centro-Oeste", "Sul", "Nordeste", "Norte",],
                series: series,
                legend: {
                    position: "bottom",
                    formatter: function (seriesName, opts) {
                        return "<span style='color: white;'>" + seriesName + "</span>";
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                colors: ["#ee663a", "#ffa467", "#ffdc96", "#4274a0"],
            };
            var chart = new ApexCharts(document.querySelector("#pie"), options);
            chart.render()

        },
        renderChart2(parametrosTodos) {
            document.querySelector("#pie2").innerHTML = '';
            let balancoEnergetico = parametrosTodos

            var series = [];
            var labels = [];
            var somaNuclear = parseFloat(balancoEnergetico.somaNuclear).toFixed(2);
            var somaEolica = parseFloat(balancoEnergetico.somaEolica).toFixed(2);
            var somaHidraulica = parseFloat(balancoEnergetico.somaHidraulica).toFixed(2);
            var somaSolar = parseFloat(balancoEnergetico.somaSolar).toFixed(2);
            var somaTermica = parseFloat(balancoEnergetico.somaTermica).toFixed(2);
            var somaImportacao = parseFloat(balancoEnergetico.somaImportacao).toFixed(2);

            series.push(Number(somaNuclear));
            series.push(Number(somaEolica));
            series.push(Number(somaHidraulica));
            series.push(Number(somaSolar));
            series.push(Number(somaTermica));
            series.push(Number(somaImportacao));
            var options = {
                chart: {
                    width: 300,
                    type: "pie",
                },
                labels: ["Nuclear", "Eólica", "Hidráulica", "Solar", "Térmica", "Importação"],

                series: series,
                legend: {
                    position: "bottom",
                    formatter: function (seriesName, opts) {
                        return "<span style='color: white;'>" + seriesName + "</span>";
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: 0,
                },
                colors: ["#ee663a", "#ffa467", "#ffdc96", "#4274a0", "#004971", "#b0b0b0"],
            };
            var chart = new ApexCharts(document.querySelector("#pie2"), options);
            chart.render()

        },
        renderChart3(parametrosMinutos) {
            document.querySelector("#graficoEnergetico").innerHTML = '';
            var $balancoEnergeticoGraficoLinha = parametrosMinutos;

            $balancoEnergeticoGraficoLinha.sort((a, b) => new Date(b.data) - new Date(a.data));

            // Obter a data do primeiro objeto (que agora será o mais recente)
            let dataMaisRecente = moment($balancoEnergeticoGraficoLinha[0].data).utc().format('DD-MM-YYYY HH:mm');



            let dataMaisRecenteGraficoLinha = document.getElementById("dataMaisRecenteGraficoLinha");

            // Atualizar o conteúdo da div com a data
            dataMaisRecenteGraficoLinha.innerText = "Atualização: " + dataMaisRecente;

            // Extrair as horas e os valores de cada região
            var horas = [];
            var sudesteECentroOeste_total = [];
            var sul_total = [];
            var nordeste_total = [];
            var norte_total = [];

            $balancoEnergeticoGraficoLinha.forEach(function (dado) {
                horas.push(dado.data.substring(11, 16)); // Extrai apenas a hora do formato "HH:mm"
                sudesteECentroOeste_total.push((parseFloat(dado.sudesteECentroOeste_total)).toFixed(2));
                sul_total.push((parseFloat(dado.sul_total)).toFixed(2));
                nordeste_total.push((parseFloat(dado.nordeste_total)).toFixed(2));
                norte_total.push((parseFloat(dado.norte_total)).toFixed(2));
            });

            // Opções do gráfico
            var options = {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                series: [{
                    name: 'Sudeste e Centro-Oeste',
                    data: sudesteECentroOeste_total
                }, {
                    name: 'Sul',
                    data: sul_total
                }, {
                    name: 'Nordeste',
                    data: nordeste_total
                }, {
                    name: 'Norte',
                    data: norte_total
                }],
                xaxis: {
                    categories: horas,
                    labels: {
                        style: {
                            colors: 'white'
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: 'white'
                    },
                    axisTicks: {
                        show: true,
                        color: 'white'
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: 'white'
                        }
                    }
                },
                colors: ["#ee663a", "#ffa467", "#ffdc96", "#4274a0"], // Define as cores das séries
                tooltip: {
                    theme: 'dark'
                },
                legend: {
                    labels: {
                        colors: 'white'
                    }
                }
            };

            var chart = new ApexCharts(document.querySelector("#graficoEnergetico"), options);

            chart.render();
        },
        balancoGeracaoCarga(agora) {

            const diferencaGeracao = agora.geracao - agora.compargeracao;

            const diferencaCarga = agora.carga - agora.comparcarga;

            this.data = moment(agora.data).utc().format('DD-MM-YYYY HH:mm');;
            this.geracao = agora.geracao.toFixed(2);
            this.carga = agora.carga.toFixed(2);
            this.porcentagemGeracao = (diferencaGeracao / agora.compargeracao) * 100;
            this.porcentagemCarga = (diferencaCarga / agora.comparcarga) * 100;

            if (agora.geracao >= agora.compargeracao) { this.isMaiorGeracao = true; } else { this.isMaiorGeracao = false; }

            if (agora.carga >= agora.comparcarga) { this.isMaiorCarga = true; } else {  this.isMaiorCarga = false; }



        },
    }


}
</script>