
const axios = require('axios');

// Objeto contendo os métodos para fazer requisições
const apiMethods = {

  async usuarios() {
    try {
        let response = []
        response = await axios.get(`https://apienerge.apololab.net:3001/usuarios`);
        // console.log("response.data",response.data);
        return response.data            ;
    } catch (error) {
        console.error('Erro ao obter usuarios:', error);
        throw error;
    }
},



    async login(email, password) {
        const user = {
            email: email,
            password:  password
        }
        try {
            const response = await axios.post('https://apienerge.apololab.net:3001/login', user);
            if(response.data.token){
                console.log("front", response.data.token);
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('user', response.data.nome);
                return response.data.status;
            }else {
                // sessionStorage.removeItem('token', null);
                // sessionStorage.removeItem('user', null);
                return response.data.status;
            }
          } catch (error) {
            console.error('Erro ao login:', error);
            throw error;
          }
    },

    async parametros() {
        try {
            let response = []
            response = await axios.get(`https://apienerge.apololab.net:3001/valoresparametros`);
            // console.log("response.data",response.data);
            return response.data            ;
        } catch (error) {
            console.error('Erro ao obter parametros:', error);
            throw error;
        }
    },

    async graficoParametros() {
        try {
            let response = []
            response = await axios.get(`https://apienerge.apololab.net:3001/graficoparametros`);
            return response.data            ;
        } catch (error) {
            console.error('Erro ao obter parametros:', error);
            throw error;
        }
    },

    async graficoBalancoEnergeticoAgora() {
        try {
            let response = []
            response = await axios.get(`https://apienerge.apololab.net:3001/graficobalancoenergeticoagora`);
            return response.data            ;
        } catch (error) {
            console.error('Erro ao obter graficobalancoenergeticoagora:', error);
            throw error;
        }
    },

    async graficoBalancoEnergeticoAgoraTodas() {
        try {
            let response = []
            response = await axios.get(`https://apienerge.apololab.net:3001/graficobalancoenergeticoagoratodas`);
            return response.data            ;
        } catch (error) {
            console.error('Erro ao obter graficobalancoenergeticoagora:', error);
            throw error;
        }
    },

    async graficoBalancoEnergeticoMinuto() {
        try {
            let response = []
            response = await axios.get(`https://apienerge.apololab.net:3001/graficobalancoenergeticominuto`);
            return response.data            ;
        } catch (error) {
            console.error('Erro ao obter graficobalancoenergeticominuto:', error);
            throw error;
        }
    },

    async salvarSimulador(simuladorData) {
        try {
          const response = await axios.post('https://apienerge.apololab.net:3001/salvarSimulador', simuladorData);
          // Retorne os dados da resposta
          return response.data;
        } catch (error) {
          console.error('Erro ao salvarSimulador:', error);
          throw error;
        }
      },

    async graficoBalancoenErgeticoPie () {
        try {
            const response = await axios.get('https://apienerge.apololab.net:3001/graficobalancoenergeticopie');
             // Retorne os dados da resposta
            return response.data;
          } catch (error) {
            console.error('Erro ao salvarSimulador:', error);
            throw error;
          }
    },


    async balancoenergeticoAgora () {
        try {
            const response = await axios.get('https://apienerge.apololab.net:3001/balancoenergeticoagora');
            // Retorne os dados da resposta
            return response.data;
          } catch (error) {
            console.error('Erro ao balancoenergeticoagora:', error);
            throw error;
          }
    },


    async criaUsuarios (dados) {
        try {
            const response = await axios.post('https://apienerge.apololab.net:3001/createUsers', dados);
            // Retorne os dados da resposta
            return response.data;
          } catch (error) {
            console.error('Erro ao balancoenergeticoagora:', error);
            throw error;
          }
    },

  async validation(token) {
    try {
        const response = await axios.get(`https://apienerge.apololab.net:3001/validation/${token}`);
         // Retorne os dados da resposta
        return response.data;
      } catch (error) {
        console.log('Erro ao validation:', error);
        throw error;
      }
    },

    async alteraSenha(dados) {
      try {
        const response = await axios.post('https://apienerge.apololab.net:3001/alterasenha', dados);
         // Retorne os dados da resposta
        return response.data;
      } catch (error) {
        console.log('Erro ao alteraSenha:', error);
        throw error;
      }
    },

    async editaUsuario(dados){
      try {
        const response = await axios.post('https://apienerge.apololab.net:3001/editarUsuario', dados);
        // Retorne os dados da resposta
        return response.data;
      } catch (error) {
        console.error('Erro ao editaUsuario:', error);
        throw error;
      }
    },

    async deleteUsuario(dados){
      try {
        const response = await axios.post('https://apienerge.apololab.net:3001/deleteUsuario', dados);
        // Retorne os dados da resposta
        return response.data;
      } catch (error) {
        console.error('Erro ao deleteUsuario:', error);
        throw error;
      }
    }

    // Adicione mais métodos conforme necessário
};

// Exporte o objeto contendo os métodos
module.exports = apiMethods;
