<template>
  <div class="home">
    <div class="app-container">
     <!-- App header ends -->
     <!-- App body starts -->
      <div class="app-body">
        <!-- Container starts -->
        <div class="container-fluid">
         <!-- Row start -->
          <div class="row align-items-center mb-3">
            <div class="col-12 col-xl-4">
              <h2 class="mb-2">Dashboard analítico</h2>
              <h6 class="mb-2 fw-light text-dark small">
                GERA - Cálculo de economia
              </h6>
            </div>
            <div class="col-12 col-xl-7">
              <div class="row g-3">
                <div class="col-sm-4">
                  <div class="card p-3 rounded-2 d-flex flex-row flex-wrap">
                    <div class="ms-2">
                      <h4 class="m-0 fw-semibold">{{ custo_eletrica }} </h4>
                      <h6 class="m-0 fw-light">Custo de energia elétrica</h6><span>R$/mês</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card p-3 rounded-2 d-flex flex-row flex-wrap">
                    <div class="ms-2">
                      <h4 class="m-0 fw-semibold">{{ custo_diesel }} </h4>
                      <h6 class="m-0 fw-light">Custo de diesel</h6><span>R$/mês</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card p-3 rounded-2 d-flex flex-row flex-wrap">
                    <div class="ms-2">
                      <h4 class="m-0 fw-semibold">{{ economia_final }} </h4>
                      <h6 class="m-0 fw-light">Economia final</h6><span>R$/mês</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Row end -->

          <!-- Row start -->
          <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-body" style="color: black;">
                      <h6 style="color: white;" >Últimas simulações</h6>
                      <ChartComponent  />
                    </div>
                </div>
                
            </div>
         </div>
          <!-- Grafico Geração de Energia start -->
          <GeracaoHoje />
          <!-- Row ends -->
       </div>
        <!-- Container ends -->
     </div>
      <!-- App body ends -->
      <!-- App footer start -->
      <div class="app-footer">
        <span>© Apolo Lab 2024</span>
      </div>
      <!-- App footer end -->

    </div>
  </div>
</template>

<script>

import apiMethods from './services/homeService';
// @ is an alias to /src

import ChartComponent from '@/components/ChartComponent.vue';
import GeracaoHoje from '@/components/GeracaoHoje.vue';

export default {
  name: 'HomeView',
  components: {
    ChartComponent,
    GeracaoHoje
  },
  data() {
    return {
      custo_eletrica: null,
      custo_diesel: null,
      economia_final: null
    }
  },
  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    },
    
  },
  async created() {
    let parametros = await apiMethods.parametros();
    this.custo_eletrica = parametros[0].custo_eletrica;
    this.custo_diesel =   parametros[0].custo_diesel;
    this.economia_final = parametros[0].economia_final;
  }
}
</script>
